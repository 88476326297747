<template>
  <div class="mod-dutyPlan">
    <el-form
      :inline="true"
      :model="dataForm"
      :rules="dataFormRules"
      ref="dataFormDom"
    >
      <el-form-item prop="type">
        <el-select
          v-model="dataForm.type"
          clearable
          filterable
          placeholder="请选择地点"
        >
          <el-option
            v-for="item in typeOpt"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="dutyDate">
        <el-date-picker
          value-format="yyyy-MM"
          v-model="dataForm.dutyDate"
          type="month"
          placeholder="请选择值班日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="searchHandler">查询</el-button>
        <el-button type="primary" @click="openAddOrUpdate()">新增</el-button>
      </el-form-item>
    </el-form>

    <div class="calendar-wrapper" v-loading="calendarLoading">
      <el-calendar v-model="cur_date" v-if="isSearch">
        <template slot="dateCell" slot-scope="{ date, data }">
          <div
            style="width: 100%; height: 100%"
            :class="data.isSelected ? 'is-selected' : ''"
            @click="openAddOrUpdate2(date, data)"
          >
            <div v-html="contentEcho(data)"></div>
          </div>
        </template>
      </el-calendar>

      <el-empty v-else description="请先选择条件并搜索"></el-empty>
    </div>

    <add-or-update
      ref="addOrUpdateDom"
      v-if="AUVisible"
      @refreshData="refreshData"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./dutyPlan-add-or-update";

export default {
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      AUVisible: false, // 新增或修改组件状态
      calendarLoading: false, // 日历加载状态
      typeOpt: [
        { id: 1, label: "首都机场", value: 0 },
        { id: 2, label: "大兴机场", value: 1 },
      ],
      dataForm: {
        type: "",
        dutyDate: "",
        page: 1,
        limit: 99999,
      },
      dataList: [
        { time: "2023-01-14", name: "jack" },
        { time: "2023-01-18", name: "rose" },
        { time: "2023-01-04", name: "sam" },
      ],
      cur_date: new Date(),
      dataFormRules: {
        type: [{ required: true, message: "地点不能为空", trigger: "blur" }],
        dutyDate: [
          { required: true, message: "值班日期不能为空", trigger: "blur" },
        ],
      },
      isSearch: false, // 是否已搜索
      dutyplanList: [], // 值班计划列表
    };
  },
  watch: {
    isSearch(newVal) {
      if (newVal) {
        // this.$nextTick(() => {
        //   let prevBtn = document.querySelector(
        //     ".el-calendar__button-group .el-button-group>button:nth-child(1)"
        //   );
        //   prevBtn.addEventListener("click", (e) => {
        //     this.getDutyplanList();
        //   });
        //   let nextBtn = document.querySelector(
        //     ".el-calendar__button-group .el-button-group>button:nth-child(3)"
        //   );
        //   nextBtn.addEventListener("click", () => {
        //     this.getDutyplanList();
        //   });
        //   //点击今天
        //   let todayBtn = document.querySelector(
        //     ".el-calendar__button-group .el-button-group>button:nth-child(2)"
        //   );
        //   todayBtn.addEventListener("click", () => {
        //     this.getDutyplanList();
        //   });
        // });
      }
    },
    cur_date(newVal) {
      if (this.dataForm.dutyDate == newVal) return;

      let date = new Date(newVal);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let cur_date = `${year}-${month > 9 ? month : "0" + month}`;

      let data_date = new Date(this.dataForm.dutyDate);
      let date_year = data_date.getMonth() + 1;

      this.dataForm.dutyDate = cur_date;

      // 切换到其它月 重新获取数据
      if (month != date_year) {
        this.getDutyplanList();
      }
    },
  },
  created() {
  },
  methods: {
    // 值班计划日历回显
    contentEcho(e) {
      let tar_idx = "";

      let result = this.dutyplanList.some((ele, idx) => {
        // console.log(e.day, ele.dutyDate.split(" ")[0], e.day == ele.dutyDate.split(" ")[0])
        if (e.day == ele.dutyDate.split(" ")[0]) {
          tar_idx = idx;
        }
        return e.day == ele.dutyDate.split(" ")[0];
      });

      //   存在值班人
      if (result) {
        return (
          e.day.split("-").slice(2).join("") +
          "" +
          `<div>${this.dutyplanList[tar_idx]["ename"]}</div>`
        );
      } else {
        return e.day.split("-").slice(2).join("") + "";
      }
    },

    // 获取值班计划列表
    getDutyplanList() {
      this.calendarLoading = true;

      this.$http.dutyplan
        .list(this.dataForm)
        .then((res) => {
          this.calendarLoading = false;
          this.isSearch = true;

          if (res.data && res.data.list && res.data.list.length > 0) {
            this.dutyplanList = res.data.list;
          } else {
            this.dutyplanList = [];
          }
        })
        .catch(() => {
          this.calendarLoading = false;
          this.isSearch = true;
        });
    },

    // 新增或修改组件关闭,重获数据
    refreshData() {
      if((this.dataForm.type+'') && this.dataForm.dutyDate) {
        this.getDutyplanList();
      }
    },

    // 点击搜索
    searchHandler() {
      this.$refs["dataFormDom"].validate((valid) => {
        if (valid) {
          this.cur_date = this.dataForm.dutyDate;
          this.getDutyplanList();
        }
      });
    },
    // 打开新增或修改组件
    openAddOrUpdate(id) {
      this.AUVisible = true;
      this.$nextTick(() => {
        this.$refs["addOrUpdateDom"].init(id);
      });
    },
    openAddOrUpdate2(e, e2) {
      let date = new Date(e);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let cur_date = `${year}-${month > 9 ? month : "0" + month}`;

      // 不是当月，切换当下个月。
      if (cur_date != this.dataForm.dutyDate) return;

      let result = this.dutyplanList.filter((ele) => {
        return ele.dutyDate.split(" ")[0] == e2.day;
      });

      // 修改
      if (result && result.length > 0) {
        this.AUVisible = true;
        this.$nextTick(() => {
          this.$refs["addOrUpdateDom"].init(result[0]["id"]);
        });
      }
      // 新增
      else {
        this.AUVisible = true;
        this.$nextTick(() => {
          this.$refs["addOrUpdateDom"].init({time: e2.day});
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-wrapper {
  min-height: 500px;
}
</style>