<template>
  <el-dialog
    :visible.sync="visible"
    :title="dataForm.id ? '修改' : '新增'"
    custom-class="dialog-warpper"
    width="1000px"
    top="5vh"
  >
    <el-form
      class="data-form"
      :model="dataForm"
      :rules="dataFormRules"
      ref="dataFormDom"
    >
      <el-card>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="员工" :contentStyle="{ width: '300px' }">
            <template slot="label">
              <span class="requireSyb">*</span>
              员工
            </template>

            <el-form-item prop="eid">
              <el-select
                v-model="dataForm.eid"
                @change="eidChange"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in employeeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="日期" :contentStyle="{ width: '300px' }">
            <template slot="label">
              <span class="requireSyb">*</span>
              日期
            </template>

            <el-form-item prop="dutyDate">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="dataForm.dutyDate"
                style="width: 100%"
                type="date"
                placeholder="请选择值班日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="地点" :contentStyle="{ width: '300px' }">
            <template slot="label">
              <span class="requireSyb">*</span>
              地点
            </template>

            <el-form-item prop="type">
              <el-select
                v-model="dataForm.type"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in typeOpt"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </el-form>

    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitHandler">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      employeeList: [], // 员工列表
      typeOpt: [
        { id: 1, label: "首都机场", value: 0 },
        { id: 2, label: "大兴机场", value: 1 },
      ],

      dataForm: {
        id: "",
        eid: "",
        ename: "",
        dutyDate: "",
      },
      dataFormRules: {
        type: [{ required: true, message: "地点不能为空", trigger: "blur" }],
        eid: [{ required: true, message: "值班员工不能为空", trigger: "blur" }],
        dutyDate: [
          { required: true, message: "值班日期不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(e) {
      console.log(e);
      this.visible = true;

      this.dataInit();

      this.getEmployeeList();

      // 存在传参（可能是修改也可能是新增）
      if (e) {
        // 新增
        if (typeof e == "object") {
          this.dataForm.dutyDate = e.time+' 00:00:00'
        }
        // 修改
        else {
          this.dataForm.id = e;
          this.getDutyplanInfo();
        }
      }
      // 新增
      else {
      }
    },

    // 数据初始化
    dataInit() {
      this.$nextTick(() => {
        this.$refs["dataFormDom"].clearValidate();
      });

      this.dataForm = {
        id: "",
        eid: "",
        ename: "",
        dutyDate: "",
      };
    },
    // 获取值班计划信息
    getDutyplanInfo() {
      this.$http.dutyplan.info({id: this.dataForm.id}).then((res) => {
        if(res.data) {
          this.dataForm = res.data
        }
      });
    },
    // 获取员工列表
    getEmployeeList() {
      this.$http.employee
        .list({
          page: 1,
          list: 99999,
        })
        .then((res) => {
          if (res.data && res.data.list && res.data.list.length > 0) {
            this.employeeList = res.data.list;
          } else {
            this.employeeList = [];
          }
        });
    },

    // 选择员工发生变化
    eidChange(e) {
      let result = this.employeeList.filter((ele) => {
        return ele.id == e;
      });
      if (result && result.length > 0) {
        this.dataForm.ename = result[0].name;
      }
    },

    // 提交处理
    submitHandler() {
      let dataForm = this.dataForm;

      this.$refs["dataFormDom"].validate((valid) => {
        if (valid) {
          // 修改
          if (this.dataForm.id) {
            this.updateHandler(dataForm);
          }
          // 新增
          else {
            this.addHandler(dataForm);
          }
        }
      });
    },

    // 新增计划
    addHandler(propDF) {
      this.$http.dutyplan.save(propDF).then((res) => {
        this.$message({
          message: "新增成功",
          type: "success",
          duration: 1000,
          onClose: () => {
            this.visible = false;
            this.$nextTick(() => {
              this.$emit("refreshData");
            });
          },
        });
      });
    },
    // 修改计划
    updateHandler(propDF) {
      this.$http.dutyplan.update(propDF).then((res) => {
        this.$message({
          message: "修改成功",
          type: "success",
          duration: 1000,
          onClose: () => {
            this.visible = false;
            this.$nextTick(() => {
              this.$emit("refreshData");
            });
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .el-descriptions {
    .el-descriptions-item__label {
      width: 100px;
      text-align: center;
      color: #606266;
    }

    .el-descriptions-item__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}

:deep() {
  .pic-descriptions__wrapper {
    position: relative;
    padding-right: 170px;

    .pic-box {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 170px;
      border: 1px solid #ebeef5;
      border-left: 0;
      padding: 5px;

      .avatar-uploader {
        height: 100%;

        .el-upload {
          width: 100%;
          height: 100%;
          border: 1px dashed #d9d9d9;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          cursor: pointer;
          overflow: hidden;
          transition: 0.1s;

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            text-align: center;
          }
        }

        .el-upload:hover {
          border-color: #11bb8d;
        }

        .img-box {
          position: relative;
          width: 100%;
          height: 100%;

          .avatar {
            width: 100%;
            height: 100%;
            display: block;
          }

          .delete-box {
            width: fit-content;
            height: fit-content;
            padding: 10px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: rgba($color: #000000, $alpha: 0.3);
            display: none;

            .delete-icon {
              color: #fff;
              font-size: 20px;
            }
          }
        }

        .img-box:hover {
          .delete-box {
            display: block;
          }
        }
      }
    }
  }
}

// 必填标识
.requireSyb {
  color: red;
  margin-right: 2px;
  font-size: 18px;
}
</style>